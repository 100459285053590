import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'url-search-params-polyfill';
import 'promise-polyfill/src/polyfill';

import {Image as KonvaImage, Layer, Stage} from 'react-konva';
import ReactPlayer from 'react-player';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';



import Angst from './emoji/Angst.png';
import Begeistert from './emoji/Begeistert.png';
import Ekel from './emoji/Ekel.png';
import Erroeten from './emoji/Erroeten.png';
import Freude from './emoji/Freude.png';
import Genervt from './emoji/Genervt.png';
import Gleichgueltig from './emoji/Gleichgueltig.png';
import Lachen from './emoji/Lachen.png';
import Langeweile from './emoji/Langeweile.png';
import Leichtes_laecheln from './emoji/Leichtes_laecheln.png';
import Liebe from './emoji/Liebe.png';
import Luege from './emoji/Luege.png';
import Mundwinkel_unten from './emoji/Mundwinkel unten.png';
import Party from './emoji/Party.png';
import Skeptisch from './emoji/Skeptisch.png';
import Sonnenbrille from './emoji/Sonnenbrille.png';
import Trauer from './emoji/Trauer.png';
import Ueberraschung from './emoji/Ueberraschung.png';
import Wut from './emoji/Wut.png';

const randomInt = require('random-int');

Sentry.init({
  dsn: 'https://227662c2129346209d95d0a64994d306@sentry.smartmunk.com/11',
  autoSessionTracking: true,
  integrations: [
    new Integrations.BrowserTracing(),
  ],
  tracesSampleRate: 1.0,
});

class App extends React.Component {

  constructor(props) {
    super(props);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    this.state = {
      emojis: [],
      reactions: [],
      playedSeconds: 0,
      buttonsDisabled: true,
      playing: false,
      video: urlParams.get('video'),
    };

    this.fullSet = {

      'Angst': Angst,
      'Begeistert': Begeistert,
      'Ekel': Ekel,
      'Erroeten': Erroeten,
      'Freude': Freude,
      'Genervt': Genervt,
      'Gleichgueltig': Gleichgueltig,
      'Lachen': Lachen,
      'Langeweile': Langeweile,
      'Leichtes_laecheln': Leichtes_laecheln,
      'Liebe': Liebe,
      'Luege': Luege,
      'Mundwinkel_unten': Mundwinkel_unten,
      'Party': Party,
      'Skeptisch': Skeptisch,
      'Sonnenbrille': Sonnenbrille,
      'Trauer': Trauer,
      'Ueberraschung': Ueberraschung,
      'Wut': Wut,

    }


    this.standardSet = [
      'Freude',
      'Trauer',
      'Ueberraschung',
      'Wut',
      'Angst',
      'Liebe',
      'Langeweile',
    ];



    this.setState({browser: this.checkBrowser()});
    this.emojis = {};
    this.emojiCount = 0;

    this.stage = React.createRef();


    this.passedSet = null;
    if(urlParams.get('set') !== null) {
      this.passedSet = urlParams.get('set').split(",");
    }

    this.questionText = "";
    if(urlParams.get('questiontext') !== null && urlParams.get('questiontext') !== "") {
      this.questionText = urlParams.get('questiontext');
    }


    this.set = this.standardSet;
    console.log(this.passedSet);
    if (this.passedSet !== null && this.passedSet.length > 0 && this.passedSet[0] !== "") {
      this.set = this.passedSet;
    }

    this.emojiImages = [];

    for (let i in this.set) {
      let name = this.set[i];
      if(this.fullSet[name] !== undefined) {
        let src = this.fullSet[name];
        this.emojiImages[name] = new Image();
        this.emojiImages[name].src = src;
      }
    }

  }



  componentDidMount() {
    const self = this;
    document.getElementsByTagName('canvas')[0].addEventListener('click', () => {
      self.setState({playing: true});
    });
    document.getElementsByTagName('canvas')[0].addEventListener('touchstart',
        () => {
          self.setState({playing: true});
        });
  }

  createReaction(emoji) {
    let {reactions} = this.state;
    reactions.push({
      emoji,
      playedSeconds: this.state.playedSeconds,
    });
    this.setState({reactions});
    for (let i = 1; i <= randomInt(3, 5); i++) {
      this.createEmoji(emoji);
    }
  }

  checkBrowser() {
    var sBrowser, sUsrAg = navigator.userAgent;

// The order matters here, and this may report false positives for unlisted browsers.

    if (sUsrAg.indexOf('Firefox') > -1) {
      sBrowser = 'Mozilla Firefox';
      // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
    } else if (sUsrAg.indexOf('SamsungBrowser') > -1) {
      sBrowser = 'Samsung Internet';
      // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
    } else if (sUsrAg.indexOf('Opera') > -1 || sUsrAg.indexOf('OPR') > -1) {
      sBrowser = 'Opera';
      // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
    } else if (sUsrAg.indexOf('Trident') > -1) {
      sBrowser = 'Microsoft Internet Explorer';
      // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
    } else if (sUsrAg.indexOf('Edge') > -1) {
      sBrowser = 'Microsoft Edge';
      // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
    } else if (sUsrAg.indexOf('Chrome') > -1) {
      sBrowser = 'Google Chrome or Chromium';
      // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
    } else if (sUsrAg.indexOf('Safari') > -1) {
      sBrowser = 'safari';
      // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
    } else {
      sBrowser = 'unknown';
    }

    return sBrowser;
  }

  createEmoji(emoji) {
    this.emojiCount++;
    let {emojis} = this.state;
    const id = this.emojiCount;

    const x = randomInt(this.stage.current.width() - 200,
        this.stage.current.width() - 100);
    emojis.push({
      id,
      emoji,
      fontsize: randomInt(10, 70),
      x,
      y: 500,
    });

    setTimeout(() => {
      let ref = this.emojis[id];
      ref.to({
        y: -100,
        x: randomInt((x - 30), (x + 30)),
        opacity: 0,
        duration: randomInt(1, 3),
      });
    }, 10);

    setTimeout(() => {
      let {emojis} = this.state;
      emojis = emojis.filter(emoji => emoji.id !== id);
      this.setState({emojis});
    }, 4000);

    this.setState({emojis});
  }

  async saveDataAndRedirect() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const formData = new FormData();
    formData.append('project', urlParams.get('project'));
    formData.append('imp', urlParams.get('imp'));
    formData.append('json', JSON.stringify(this.state.reactions));
    formData.append('video', urlParams.get('video'));

    const request = new XMLHttpRequest();
    request.open('POST', './store-data.php');
    request.send(formData);

    request.onload = event => {
      let project = urlParams.get('project');
      let state = urlParams.get('state');
      let empty = (this.state.reactions.length === 0).toString();
      window.location.href = `https://analysis.dialego.de/b/2192/${project}?state=${state}&empty=${empty}`;
    };
  }

  render() {

    if (this.stage.current) {
      let video = document.getElementsByTagName('video')[0];
      if (video) {
        this.stage.current.width(video.offsetWidth);
        this.stage.current.height(video.offsetHeight);
      }
    }

    let browser = this.checkBrowser();
    var component = (<ReactPlayer playing={this.state.playing}
                                  width="100%"
                                  ref={this.video}
                                  progressInterval={10}
                                  playsinline={true}
                                  light="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNk+A8AAQUBAScY42YAAAAASUVORK5CYII="
                                  playIcon={<button
                                      className="btn btn-primary">Video
                                    starten</button>}

                                  onClick={() => {
                                    this.setState({playing: true});
                                  }}

                                  onStart={() => {
                                    this.setState({buttonsDisabled: false});
                                  }}
                                  onEnded={() => {
                                    this.setState({buttonsDisabled: true});
                                    this.saveDataAndRedirect();
                                  }}
                                  onProgress={e => {
                                    this.setState(
                                        {playedSeconds: e.playedSeconds});
                                  }}
                                  url={`${this.state.video}`}
                                  style={{position: 'absolute'}}
    />);
    if (browser === 'safari') {

      component = (<ReactPlayer playing={this.state.playing}
                                width="100%"
                                controls
                                ref={this.video}
                                progressInterval={10}
                                playsinline={true}
                                onClick={() => {
                                  this.setState({playing: true});
                                }

                                }
                                onStart={() => {
                                  this.setState({buttonsDisabled: false});
                                }}
                                onEnded={() => {
                                  this.setState({buttonsDisabled: true});
                                  this.saveDataAndRedirect();
                                }}
                                onProgress={e => {
                                  this.setState(
                                      {playedSeconds: e.playedSeconds});
                                }}
                                url={`${this.state.video}`}
                                style={{position: 'absolute'}}
      />);
    }

    //build buttons 
    var buttons = [];
    for (let i in this.set) {
      let name = this.set[i];
      if(this.fullSet[name] !== undefined) {

        let src = this.fullSet[name];
        buttons.push(<button className="btn btn-lg col-3"
                             disabled={this.state.buttonsDisabled}
                             onClick={() => {
                               this.createReaction(name);
                             }}>
          <img src={src} alt="" className="img-fluid"/>
        </button>);
      }
    }

    return (
        <div className="container-fluid">
  
              <div style={{color: '#005096',fontSize: '18px',lineHeight: 'normal',fontFamily: 'Verdana,Arial,sans-serif',textAlign:'center',marginLeft:'25px',marginRight:'25px',paddingBottom:'30px'}}>{this.questionText}</div>

            <div style={{
            width: '640px',
            maxWidth: '100%',
            margin: '0 auto',
            position: 'relative',
          }}>
            {component}
            <Stage ref={this.stage} width={640} height={360}>
              <Layer>
                {this.state.emojis.map(emoji => <KonvaImage
                        image={this.emojiImages[emoji.emoji]}
                        ref={node => {
                          this.emojis[emoji.id] = node;
                        }}
                        key={emoji.id}
                        width={emoji.fontsize}
                        height={emoji.fontsize}
                        x={emoji.x}
                        y={emoji.y}
                    />,
                )}
              </Layer>
            </Stage>

            <div className="bg-neutral text-center">

              {buttons}
            </div>
          </div>
        </div>
    );
  }
}

export default App;
